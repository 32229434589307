@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;

// @include foundation-prototype-classes;

@include motion-ui-transitions;
@include motion-ui-animations;


@font-face {
    font-family: 'carley_cobold';
    src: url('../img/fonts/carley_co_bold-webfont.woff2') format('woff2'),
         url('../img/fonts/carley_co_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'carley_colight';
    src: url('../img/fonts/carley_co_light-webfont.woff2') format('woff2'),
         url('../img/fonts/carley_co_light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'carley_coregular';
    src: url('../img/fonts/carley_co_regular-webfont.woff2') format('woff2'),
         url('../img/fonts/carley_co_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}





body{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    color: #4A4A4A;
    line-height: 26px;
}

div{
    // border: 1px solid pink;
}


h1, h2, h3{
	font-family: 'carley_cobold';
}
h1{
    color: #9DC41F;
}
h1.grau{
  color: #afafaf;
}

a{
    color: #9A7149;
}
a:hover{
    text-decoration: underline;
    color: #733604;
}
.margintop10{
  margin-top: 10px;
}

.margintop20{
  margin-top: 20px;
}

.margintop30{
  margin-top: 30px;
}
.margintop40{
  margin-top: 40px;
}

.nopadding{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.introtext{
  font-size: 18px;
  line-height: 30px;
}

.bildkommentar{
  color: #9B9B9B;
  font-size: 14px;
}

.trennungen{
  -moz-hyphens: auto;
   -o-hyphens: auto;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;
}

.linkhover{
  cursor: pointer;
}
.linkhover:hover{
  text-decoration: underline;
}

//Mobilemenu

#mobilemenu{
    position: fixed;
    top: 0px;
    display: none;
    padding-top: 20px;
    background: white;
    z-index: 9999;
}

#mobilemenu .headline{
    padding: 10px;
    font-size: 20px;
    color: #A9A9A9;
    // line-height: 37px;
}

#mobilemenu .trennlinie{
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    height: 1px;
    border-bottom: 1px solid #EBEBEB;
}

#mobilemenu .close_x{
    width: 22px;
    height: 22px;
    background: url("../img/close_x_grau.svg") no-repeat 50% 50%;
    float:right;
}

#mobilemenu .menuitems{
    font-size: 20px;
    color: #A9A9A9;
    line-height: 37px;
    text-align: center;
}

#mobilemenu .scrollarea{
    overflow: auto;
}

.headerrow{
    flex-flow: row;
    min-height: 109px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    max-width: 1020px;
}

.bodyrow{
    display: flex;
    flex-flow: row wrap;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    max-width: 1020px;
}

.logowrapper{
    flex: 1 0;
    display: flex;
    align-items: center;
}

.menuitemswrapper{
    flex: 1 0;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 17px;

}

.alternativemenuzeile{
    flex-flow: row;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    width: 100%;
}


.produktelink{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}
.produktelink a{
    font-family: carley_cobold;
    font-size: 31px;
    color: #9A7149;
}
.produktelink a:hover{
    text-decoration: underline;
}

.smallinfo{
    display: flex;
} 

.burger{
    width: 22px;
    height: 22px;
    background: url("../img/burger.svg") no-repeat 50% 50%;
    float:right;
    margin-right: 10px;
}


.menuzeile{
    color: #A9A9A9;
    display: flex;
    justify-content: flex-end;

    a{
        font-size: 16px;
        color: #565656;
        margin-left: 20px;
    }
    a:hover{;
        color: #9A7149;
        text-decoration: underline;
    }
}


.headlineadditionlink{
    font-size: 14px;
    margin-top: 54px;
    margin-left: 5px;
}


// AKTUELL

.aktcell{
    .aktcomment{
        font-size: 16px;
        color: #7E7E7E;
        margin-bottom: 7px;
    }
    .akttitel{
        font-size: 24px;
        color: #4A4A4A;
        margin-bottom: 20px;
    }
    .aktimg{
        margin-bottom: 15px;
    }
    .aktlink{
        a{
            font-size: 16px;
            color: #9B6B44;
        }
        a:hover{
            color: #733604;
        }
    }
}



.h1margin{
    margin-top: 80px;
}

// SHOPTABELLE
.shoptabelle{
  display: flex;
  justify-content: space-between;
  
  .tablecell{
    .spaltentitel{
      font-size: 14px;
      color: #9B9B9B;
      margin-bottom: 5px;
    }
    .item{
      line-height: 31px;
    }
  }
  .tablecell.name{
    .item{
      font-size: 18px;
      color: #000000;
    }
  }
  .tablecell.ort, .tablecell.straße{
    .item{
      font-size: 16px;
      color: #454545;
      line-height: 31px;
    }
  }
}


.greybackrow{
  display: flex;
  width: 100%;
  justify-content: center;
  background: #F3F4F8;
}

// KONTAKTFORM
.kontakt{}

.kontakt p{
  margin: 0px;
  margin-top: 10px;
  line-height: 20px;
}
.kontakt a{
  font-weight: 500;
}

.kontakt .title{
  font-size: 18px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 5px;
}
.kontakt .infoblock{
  width: 70px;
  position: relative;
  display: inline-block;
  font-weight: 500;
}

.kontakt .button-contactform-send{
    font-size: 16px;
}

.kontakt .button-contactform-send:hover{
    text-decoration: underline;
}

#messagescontainer_done{
  display: none;
}
#messagescontainer_error{
  display: none;
}

#messagescontainer_done p, #messagescontainer_error p{
  margin-top: 0px;
}

#submitbutton.inaktiv{
  opacity: 0.5;
}

// OWL START
/* 
 *  Owl Carousel - Animate Plugin
 */

 .owl-carouselcollage .owl-item{
  margin: 0px !important;
 }
.owl-carouselcollage .owl-dots{
  display: none !important;
}

.owl-carouselheader .owl-dots{
  display: none !important;
}


.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 *  Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 *  Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/* 
 *  Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}


// Owl Carousel Styles
.owl-carousel .owl-dots{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.owl-carousel .owl-dot.active{
    background: #D2D2D2;
    border: 1px solid #D2D2D2;
    border-radius: 50%;
}

.owl-carousel .owl-dot{
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid #DDDDDD;
    margin: 5px;
}


.disabled{
    display: none;
}
// OWL ENDE



/* Small only */
@media screen and (max-width: 39.9375em) {
  h1{
    line-height: 73px;
    margin-bottom: 20px;
  }
    .smallhidden{
        display: none;
    }
    .logowrapper{
      width: 100%;
    }
    .menuzeile a{
      font-size: 14px;
      white-space: nowrap;
      color: #9c9c9c;
    }
    .alternativemenuzeile{
        flex-flow: column;
        align-items: center;
        margin-bottom: 10px;
    }
    .headerrow{
      min-height: 89px;
    }
    .smallinfo{
      
      font-size: 14px;
      line-height: 35px;
    }
    .smallinfo a{
      margin-left: 10px;
    }
    .shoptabelle .tablecell.name .item{
      font-size: 18px;
      color: #000000;
      white-space: nowrap;
      display: block;
      line-height: 14px;
      margin-bottom: 14px;
    }
    .spaltentitel{
      display: none;
    }

}
/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .mediumhidden{
        display: none;
    }
    .mediumhalfpaddingright {
        padding-right: 25px;
    }
    .mediumhalfpaddingleft {
        padding-left: 25px;
    }
    .smallinfo{
      display: none;
    }
        .produktelink{
      text-align: right;
      line-height: 32px;
    }
}

/* Large and up */
@media screen and (min-width: 64em) {
    .largehidden{
        display: none;
    }
    .largehalfpaddingright{
        padding-right: 25px;
    }
    .largehalfpaddingleft{
        padding-left: 25px;
    }
    .smallinfo{
      display: none;
    }
}














